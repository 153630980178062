module directives {
    export module costing {
        interface IAccountPaymentDirectiveScope extends ng.IScope {
        }

        export class accountPaymentDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/customs/accountPaymentView.html';
            scope = {
                customsPartyId: "=",
                accountPaymentId: "=",
                close: "&",
            };
            controller = "accountPaymentCtrl";
            controllerAs = "accountPaymentCtrl";

            constructor(public $q: ng.IQService) {
            }

            link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery) => {

            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($q) => new accountPaymentDirective($q);
                directive.$inject = ['$q'];

                return directive;
            }
        }

        angular.module("app").directive("gtsAccountPayment", accountPaymentDirective.factory());
    }
}